import {
  ref, onMounted, getCurrentInstance, reactive, watch, computed
} from 'vue'
import { useStore } from 'vuex'
/* eslint-disable-next-line import/named */
import {
  priceUseCase,
  getListRute,
  getAllItemsKategoriBarang,
  getDetailAgen
} from '@/domain/usecase'
import DialogSearchAgen from '@/views/shared-components/dialog/DialogSearchAgen.vue'
import 'jspreadsheet-ce/dist/jspreadsheet.css'
import jexcel from 'jspreadsheet-ce'
import { isNumber, parseInt, isEmpty } from 'lodash'
import { TResultAgentSingle } from '@/data/source/remote/api/backend/V2/msAgens-model'

export default {
  components: {
    DialogSearchAgen
  },
  setup() {
    const app = getCurrentInstance()
    const toogleSAgen = ref(false)
    const toogleSToAgen = ref(false)
    // const router = useRouter()
    const {
      $toast, $strInd
    } = app!.appContext.config.globalProperties
    const store = useStore()
    const fromAgentDefault = `${store.getters['appActiveUser/getAgent']?.name} (${store.getters['appActiveUser/getAgent']?.code})`
    const listJenisKonsumen = [
      {
        Id: 0,
        Nama: 'Perusahaan'
      },
      {
        Id: 1,
        Nama: 'Retail'
      }
    ]
    const jenisKonsumen: any = ref('')
    const listKonsumenKategori: any = ref([])
    const listKategories = ref([])
    const mappedKategori = ref([])
    const dataKategori: any = ref('')
    const kategoriSelected: any = ref('')
    const getSatuan: any = ref()
    const fromAgent = ref<number>()
    const alamatFromAgent = ref<TResultAgentSingle>()
    const toAgent = ref<number>()
    const alamatToAgent = ref<TResultAgentSingle>()
    const weight: any = ref(0)
    const showCekHargaForm = ref(false)

    // ========================
    const getJenisKonsumenRute = async () => {
      store.dispatch('showLoading')
      const fromAgentId = alamatFromAgent.value?.CityId
      const response = await getListRute({
        custom: `kotaasalid eq ${fromAgentId} and kotatujuanid eq ${alamatToAgent.value?.CityId} or (kotaasalid eq ${alamatToAgent.value?.CityId} and kotatujuanid eq ${fromAgentId})`,
        select: '&$select=HargaRetailId, HargaCompanyId, HargaRetail, HargaCompany',
        expand: '&$expand=hargaretail($select=id, isactive,kode;$expand=MsHargaKategoris($expand=kategori($select=id,kode,nama,isactive;$filter=iscompany eq false))),hargacompany($select=id, isactive,kode;$expand=MsHargaKategoris($expand=kategori($select=id,kode,nama,isactive;$filter=iscompany eq true)))'
      })

      if (response.error) {
        $toast.add({
          severity: 'error', detail: response.error, group: 'bc', life: 1500
        })
      } else {
        /* eslint-disable */
        if (response.result) {
          if (response.result.length > 0) {
            const jenisKonsumenOption = [
              response.result[0].HargaCompany.MsHargaKategoris.map((d: any) => {
                d.Nama = d.Kategori.Nama
                return d
              }),
              response.result[0].HargaRetail.MsHargaKategoris.map((d: any) => {
                d.Nama = d.Kategori.Nama
                return d
              })
            ]
            listKonsumenKategori.value = jenisKonsumenOption
          } else {
            listKonsumenKategori.value = []
          }

        } else {
          listKonsumenKategori.value = []
        }
      }
      store.dispatch('hideLoading')
    }
    const getAllKategori = async (reset = false) => {
      store.dispatch('showLoading')

      const response = await getAllItemsKategoriBarang({ filter: 'IsActive eq true' })

      if (!response.error) {
        mappedKategori.value = response.result.map((d: any) => {
          return { Id: d.Id, Kode: d.Satuan.Kode }
        })
      } else {
        $toast.add({
          severity: 'error', detail: $strInd.toast.errorAllData, group: 'bc', life: 1500
        })
      }
      store.dispatch('hideLoading')
    }
    const findAgent = async (id: number) => {
      store.dispatch('showLoading')
      let responseData = null
      const {
        result,
        error
      } = await getDetailAgen(id.toString())
      if (!error) {
        responseData = result
      }
      store.dispatch('hideLoading')
      return responseData
    }
    const onChangeFromAgentId = async (id: number) => {
      const selected = await findAgent(id)
      if (selected) {
        alamatFromAgent.value = selected
      }
    }
    const onChangeToAgentId = async (id: number) => {
      const selected = await findAgent(id)
      if (selected) {
        alamatToAgent.value = selected
      }
    }
    const getSearchAgenData = async (val: any, key: 'destination-agent' | 'origin-agent') => {
      console.log('val', val)
      if (key === 'origin-agent') {
        await onChangeFromAgentId(val.Id)
        fromAgent.value = val.KotaId
      }
      if (key === 'destination-agent') {
        await onChangeToAgentId(val.Id)
        toAgent.value = val.KotaId
      }
    }
    watch(fromAgent, (val: any, newVal: any) => {
      jenisKonsumen.value = ''
      dataKategori.value = ''
      kategoriSelected.value = ''
      if (fromAgent.value && toAgent.value) {
        showCekHargaForm.value = true
        getJenisKonsumenRute()
      }
    })
    watch(toAgent, (val: any, newVal: any) => {
      jenisKonsumen.value = ''
      dataKategori.value = ''
      kategoriSelected.value = ''
      if (fromAgent.value && toAgent.value) {
        showCekHargaForm.value = true
        getJenisKonsumenRute()
      }
    })
    watch(dataKategori, (val: any, newVal: any) => {
      const selectedKt: any = listKategories.value.find((d: any) => d.Id == dataKategori.value)
      getSatuan.value = mappedKategori.value.find((d: any) => d.Id === selectedKt?.KategoriId)
    })
    const formatCurrentcy = (val: any) => {
      return new Number(val).toLocaleString("id-ID")
    }
    // ===============================================================
    const dataExcel = ref([
      {}
    ])
    const jExcelOptions = ref({
      data: dataExcel.value,
      hideIndex: true,
      allowInsertColumn: false,
      allowDeleteColumn: false,
      allowDeleteRow: false,
      allowInsertRow: false,
      contextMenu: false,
      editable: false,
      columns: [
        {
          name: 'MinValue',
          title: 'Min',
          mask: '#.##'
        },
        {
          name: 'MaxValue',
          title: 'Max',
          mask: '#.##'
        },
        {
          name: 'HargaDd',
          title: 'D-D',
          mask: '#.##'
        },
        {
          name: 'HargaAd',
          title: 'A-D',
          mask: '#.##'
        },
        {
          name: 'HargaDa',
          title: 'D-A',
          mask: '#.##'
        },
        {
          name: 'HargaAa',
          title: 'A-A',
          mask: '#.##'
        },
      ],
      nestedHeaders: [
        [
          {
            title: 'Berat',
            colspan: '2',
          },
          {
            title: 'Jenis Pengiriman',
            colspan: '4',
          },
        ]
      ],
      columnSorting: true,
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      onchange: (instance: any, cell: any, x: any, y: any, value: any) => { },
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      onselection: (instance: any, cell: any, x: any, y: any, value: any) => { }
    })
    const jExcelObj = ref(null) as any
    const showSubmitExcel = ref(false)
    const onTableChange = (instance: any, cell: any, x: any, y: any, value: any) => {
      if (!showSubmitExcel.value) {
        showSubmitExcel.value = true
      }
      jExcelObj.value.ignoreEvents = true
      if (!Number.isNaN(Number(value))) {
        jExcelObj.value.setValue(cell, Number(value))
      } else {
        jExcelObj.value.setValue(cell, '')
      }
      jExcelObj.value.ignoreEvents = false
    }
    const initExcel = () => {
      const docs = document.getElementById('spreadsheet')
      // console.log(docs, docs?.hasChildNodes.length)
      // jExcelOptions.value.onchange = onTableChange
      if (!docs?.hasChildNodes()) {
        jExcelObj.value = jexcel(docs, jExcelOptions.value)
        jExcelObj.value.hideIndex()
      }
      // console.log(docs, docs?.hasChildNodes.length)
      // jExcelObj.value.onchange = onTableChange
    }
    const jenisKonsumenChange = (val: any) => {
      console.log('val', [val, listKonsumenKategori.value])
      dataKategori.value = ''

      if (listKonsumenKategori.value[val]) {
        listKategories.value = listKonsumenKategori.value[val]
      }
    }
    const getDataHargaDetail = async (item: any) => {
      console.log('item', item)
      const response = await priceUseCase.getDataPriceDetail({
        priceType: jenisKonsumen.value === 0 ? 'Company' : 'Retail',
        origCityId: fromAgent.value,
        destCityId: toAgent.value,
        goodCategoryId: item.KategoriId,
        value: weight.value
      })
      console.log('res', response)
      if (!response.error) {
        const res = response.result
        dataExcel.value = [{
          ...res,
          HargaAa: res.AgentToAgentPrice,
          HargaAd: res.AgentToDoorPrice,
          HargaDa: res.DoorToAgentPrice,
          HargaDd: res.DoorToDoorPrice
        }]
        jExcelObj.value.setData((dataExcel.value.length > 0) ? dataExcel.value : [{}])
      } else {
        $toast.add({
          severity: 'error', detail: 'Gagal mengambil harga, silahkan coba kembali', group: 'bc', life: 3000
        })
      }
      store.dispatch('hideLoading')
    }
    const formValidate = () => {
      const errors = []
      if (fromAgent.value && fromAgent.value <= 0) {
        errors.push('Agen Asal')
      }
      if (toAgent.value && toAgent.value <= 0) {
        errors.push('Agen tujuan')
      }
      if (!listKonsumenKategori.value[jenisKonsumen.value]) {
        errors.push('Jenis Konsumen')
      }
      // if (kategoriSelected.value && kategoriSelected.value.length === 0) {
      if (isEmpty(kategoriSelected.value)) {
        errors.push('Kategori')
      }
      if (weight.value.length === 0 || !isNumber(parseInt(weight.value))) {
        errors.push('Berat')
      }
      return {
        hasError: errors.length > 0,
        errors
      }
    }
    const changeItemsId = async () => {
      store.dispatch('showLoading')
      const validated = formValidate()
      if (!validated.hasError) {
        jExcelObj.value.destroy()
        initExcel()
        await getDataHargaDetail(kategoriSelected.value)
      } else {
        $toast.add({
          severity: 'error', detail: `${validated.errors.join(', ')} harus diisi`, group: 'bc', life: 3000
        })
      }
      store.dispatch('hideLoading')
    }

    const cekHargaAct = () => {
      changeItemsId()
    }

    watch(dataKategori, (val, newVal) => {
      kategoriSelected.value = listKategories.value.find((d: any) => d.Id === val)
    })

    /* ================ */
    /* const dataForm = reactive({
      isActive: false,
      province: null,
      codeCity: '',
      cityName: null,
      islandId: null
    })
    const submitted = ref(false)
    const rules = {
      codeCity: {
        required: helpers.withMessage('Nama Alamat harus diisi', required)
      },
      cityName: {
        required: helpers.withMessage('Alamat Lengkap harus diisi', required)
      },
    }
    const v$ = useVuelidate(rules, dataForm) */
    onMounted(() => {
      initExcel()
      getAllKategori()
    })
    return {
      store,
      fromAgentDefault,
      fromAgent,
      alamatFromAgent,
      onChangeFromAgentId,
      toAgent,
      alamatToAgent,
      onChangeToAgentId,
      weight,
      listKategories,
      dataKategori,
      jenisKonsumen,
      listJenisKonsumen,
      jenisKonsumenChange,
      cekHargaAct,
      mappedKategori,
      getSatuan,
      toogleSAgen,
      toogleSToAgen,
      getSearchAgenData,
      showCekHargaForm
    }
  }
}
